if (typeof alpineData !== "undefined") {
    function googleReady() {
        document.dispatchEvent(new Event("google:init"));
    }
    document.addEventListener("alpine:init", () => {
        Alpine.data("googlePlacesAutocomplete", () => ({
            showClusters: alpineData.clusters,
            nearestLocation: alpineData.nearest_location,
            linkToLocationPage: alpineData.link_to_location_page,
            panToMarker: alpineData.pan_to_marker,
            panToMarkerZoomlevel: alpineData.pan_to_marker_zoom_level,
            google: {},
            locationsStyle: "",
            locations: [],
            markers: [],
            infowindow: null,
            markerCluster: null,
            address: "",
            map: null,
            autocomplete: null,
            init() {
                if (typeof window.google === "undefined") {
                    document.addEventListener("google:init", () => {
                        this.initAutocomplete();
                    });
                } else {
                    this.initAutocomplete();
                }
            },
            async initAutocomplete() {
                const { LatLng, LatLngBounds } = await google.maps.importLibrary("core");
                const { Geocoder } = await google.maps.importLibrary("geocoding");
                const { Map } = await google.maps.importLibrary("maps");
                const { Marker } = await google.maps.importLibrary("marker");
                const { Autocomplete } = await google.maps.importLibrary("places");
                const { InfoWindow } = await google.maps.importLibrary("streetView");
                this.google = {
                    LatLng,
                    LatLngBounds,
                    Geocoder,
                    Map,
                    Marker,
                    Autocomplete,
                    InfoWindow,
                };

                this.autocomplete = new this.google.Autocomplete(this.$refs.googleAutocomplete, {
                    componentRestrictions: {
                        country: ["nl"],
                    },
                });
                this.autocomplete.addListener("place_changed", () => this.handleResponse(this.autocomplete.getPlace()));

                this.map = new this.google.Map(this.$refs.googleMap, {
                    zoom: 10,
                    center: { lat: 52.9640223, lng: 5.7975938 },
                });

                this.infowindow = new this.google.InfoWindow();
                this.markerCluster =
                    this.showClusters && typeof MarkerClusterer !== "undefined"
                        ? new MarkerClusterer(this.map, [], {
                              styles: [
                                  {
                                      textColor: "white",
                                      height: 53,
                                      width: 53,
                                      url: alpineData.custom_cluster_image,
                                  },
                              ],
                          })
                        : null;

                // Listen to the resize event for styling the location list.
                window.addEventListener("resize", this.resizeListBox(), { passive: true });

                this.getLocations();

                if (this.nearestLocation && navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        const geocoder = new this.google.Geocoder();
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        this.geocodeLatLng(geocoder, pos);
                    });
                }
            },
            handleResponse(addressData) {
                // Add a custom marker for the searched location.
                this.pushCustomMarker(
                    addressData.formatted_address,
                    addressData.geometry.location.lat(),
                    addressData.geometry.location.lng(),
                );
            },
            pushCustomMarker(address, lat, lng) {
                // Remove our custom marker if it exists, so we don't have duplicates.
                this.markers = this.markers.filter((marker) => {
                    if (marker.custom === true && marker.marker) {
                        marker.marker.setVisible(false);
                        marker.marker.setMap(null);
                        marker.marker.setPosition(null);
                        marker.marker = null;

                        return false;
                    }

                    return true;
                });

                const html =
                    '<span class="infowindow-title">Eigen locatie</span><span class="infowindow-address">' +
                    address +
                    "</span>";
                const newMarker = {
                    custom: true,
                    position: {
                        lat,
                        lng,
                    },
                    marker: this.createMarker(lat, lng, html, true, true),
                };
                this.markers.push(newMarker);

                this.refreshMap();
            },
            createMarker(lat, lng, html, setMap = false, customIcon = false) {
                const marker = new this.google.Marker({
                    position: new this.google.LatLng(lat, lng),
                    map: null,
                    icon: customIcon ? alpineData.custom_marker_image : null,
                    title: html,
                });
                setMap && marker.setMap(this.map);

                marker.addListener("click", () => {
                    this.infowindow.setContent(html);
                    this.infowindow.open(this.map, marker);
                });

                return marker;
            },
            refreshMap() {
                if (this.markers.length < 1) {
                    return;
                }

                const lastMarker = this.markers[this.markers.length - 1];
                if (lastMarker.custom) {
                    this.locations = this.locations
                        .map((el) => ({
                            ...el,
                            distance: Math.round(this.haversineDistance(lastMarker.position, el.position) * 1e2) / 1e2,
                        }))
                        .sort((a, b) => a.distance - b.distance);
                }

                // Get the bounds of each marker and fit them to map.
                const bounds = new this.google.LatLngBounds();
                this.locations.forEach((location) => {
                    bounds.extend(location.position);
                });
                bounds.extend(lastMarker.position);
                this.map.fitBounds(bounds);
            },
            resultsListClick(location, map, linkToLocationPage, panToMarker, panToMarkerZoomlevel) {
                if (linkToLocationPage) {
                    window.location = location.link;
                }

                if (!this.linkToLocationPage && panToMarker) {
                    map.setZoom(parseInt(panToMarkerZoomlevel));
                    map.panTo({
                        lat: parseFloat(location.position.lat),
                        lng: parseFloat(location.position.lng),
                    });
                }
            },
            geocodeLatLng(geocoder, map) {
                geocoder.geocode(
                    {
                        location: map,
                    },
                    (results, status) => {
                        if (status === "OK") {
                            if (results[0]) {
                                this.address = results[0].formatted_address;
                                this.pushCustomMarker(results[0].formatted_address, map.lat, map.lng);
                            } else {
                                console.log("Geen resulaten gevonden.");
                            }
                        } else {
                            console.log(`Geocoder mislukt: ${status}`);
                        }
                    },
                );
            },
            getLocations() {
                let locations = alpineData.locations;
                // Add a marker for each location.
                locations.forEach((item) => {
                    const location = {
                        custom: false,
                        id: item.id,
                        title: item.title,
                        excerpt: item.excerpt,
                        thumbnail: item.thumbnail,
                        link: item.link,
                        address: item.address ?? "",
                        position: {
                            lat: parseFloat(item.acf.map.lat),
                            lng: parseFloat(item.acf.map.lng),
                        },
                    };
                    this.locations.push(location);

                    const html =
                        '<a href="' +
                        location.link +
                        '" class="infowindow-title">' +
                        location.title +
                        '</a><span class="infowindow-address">' +
                        location.address +
                        "</span>";
                    const newMarker = {
                        custom: false,
                        position: {
                            lat: parseFloat(item.acf.map.lat),
                            lng: parseFloat(item.acf.map.lng),
                        },
                        marker: this.createMarker(
                            parseFloat(item.acf.map.lat),
                            parseFloat(item.acf.map.lng),
                            html,
                            !this.showClusters,
                        ),
                    };
                    this.markers.push(newMarker);
                    this.showClusters && this.markerCluster?.addMarker(newMarker.marker, item.title);
                });

                this.refreshMap();
            },
            resizeListBox() {
                const heightString = this.$refs.locations.clientHeight - 32 + "px";
                this.locationsStyle = {
                    overflowY: "scroll",
                    height: heightString,
                };
            },
            haversineDistance(coords1, coords2) {
                function toRad(x) {
                    return (x * Math.PI) / 180;
                }

                const lat1 = coords1.lat;
                const lon1 = coords1.lng;
                const lat2 = coords2.lat;
                const lon2 = coords2.lng;

                const R = 6371; // km

                const x1 = lat2 - lat1;
                const dLat = toRad(x1);
                const x2 = lon2 - lon1;
                const dLon = toRad(x2);
                const a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                const d = R * c;

                return d;
            },
        }));
    });
}
